import React from "react";

import thermoVitalImage from "src/components/products/images/thermoVital.jpg";
import { Link } from "gatsby";
import { Row, Col, List } from "antd";
import CertificatesCarouselSecondary from "components/certificates/CarouselSecondary";
import {
  FilePptOutlined,
  FileProtectOutlined,
  SolutionOutlined,
  CheckOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "src/components/products/imgAndIntro/style.scss";

export const ThermoVitalImgAndLinks = (props) => {
  return (
    <div className="productImageLinksBlock referencia">
      <img src={thermoVitalImage} />
      <Row className="productLinks">
        <Link to={"/productos/thermovital/"}>
          <span className="linkIcon thermoVital">
            <FilePptOutlined />{" "}
            <span className="linkIconText colored thermoVital bold">
              Ver ThermoVital
            </span>
          </span>
        </Link>
        <span className="downloadIcon">
          <a
            href="/thermoVital/climateCoating-thermoVital-presentacion.pdf"
            download
          >
            <DownloadOutlined />
          </a>
        </span>
      </Row>
    </div>
  );
};

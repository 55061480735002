import React from "react";
import { LayoutGeneral } from "src/layout/LayoutGeneral";
import { RollbackOutlined } from "@ant-design/icons";
import { Link } from "gatsby";
import { Layout } from "antd";

import ParedNavarra1 from "src/components/referencias/images/humedades/pared-interior-humedad-moho-navarra/thermoVital-pared-interior-humedad-moho-navarra1.png";
import ParedNavarra2 from "src/components/referencias/images/humedades/pared-interior-humedad-moho-navarra/thermoVital-pared-interior-humedad-moho-navarra2.png";
import ParedNavarra3 from "src/components/referencias/images/humedades/pared-interior-humedad-moho-navarra/thermoVital-pared-interior-humedad-moho-navarra3.png";
import ParedNavarra4 from "src/components/referencias/images/humedades/pared-interior-humedad-moho-navarra/thermoVital-pared-interior-humedad-moho-navarra4.png";
import ParedNavarra5 from "src/components/referencias/images/humedades/pared-interior-humedad-moho-navarra/thermoVital-pared-interior-humedad-moho-navarra5.png";
import ParedNavarra6 from "src/components/referencias/images/humedades/pared-interior-humedad-moho-navarra/thermoVital-pared-interior-humedad-moho-navarra6.png";

import { ThermoVitalImgAndLinks } from "src/components/referencias/humedades/ThermoVital";

import { FacebookOutlined, InstagramOutlined } from "@ant-design/icons";

import { Image } from "antd";
import { List, Card } from "antd";
const { Header, Content } = Layout;

const data = [
  {
    src: ParedNavarra1,
  },
  {
    src: ParedNavarra2,
  },
  {
    src: ParedNavarra3,
  },
  {
    src: ParedNavarra4,
  },
  {
    src: ParedNavarra5,
  },
  {
    src: ParedNavarra6,
  },
];

const ParedesConHumedadMohoPamplona = (props) => (
  <LayoutGeneral
    {...{
      ...props,
      showPageTitle: false,
      pageTitle:
        "ThermoVital: Interior de edificio con problemas de moho y humedades",
      pageName: "Referencia ThermoVital",
      pageDescription:
        "ClimateCoating ThermoVital: Usado para evitar la aparición de mohos y humedades",
    }}
  >
    <Content className="thermoVital referencia">
      <div className="linkBack referencia">
        <Link to="/referencias/humedades/">
          <RollbackOutlined />{" "}
          <span className="colored linkText">Ir a Referencias Humedades</span>
        </Link>
      </div>

      <div className="marginTop50">
        <ThermoVitalImgAndLinks />
        <h1 className="pageTitle referencia">
          <b className="thermoVital">ClimateCoating ThermoVital: </b>
          <span className="secondText">
            Interior de Edificio afectado por mohos y humedades
          </span>
        </h1>
        <p>
          Los interiores de este edificio de Pamplona, se encontraban bastante
          afectados por humedades y mohos, gracias al uso de ClimateCoating
          thermovital se pudo revertir la situación. En las imágenes inferiores
          puede ver la capacidad del
          <strong className="grey">
            {" "}
            revestimiento para frenar la aparición de los mohos y humedades 6
            meses después
          </strong>{" "}
          consiguiendo mayor salubridad. .
        </p>
        <br />
        <br />
      </div>
      <div className="clearer"></div>
      <Image.PreviewGroup preview={{}}>
        <List
          grid={{ gutter: 16, column: 3 }}
          dataSource={data}
          renderItem={(item) => (
            <List.Item>
              <Image src={item.src} />
            </List.Item>
          )}
        />
      </Image.PreviewGroup>
      <br />
      <br />
    </Content>
  </LayoutGeneral>
);

export default ParedesConHumedadMohoPamplona;
